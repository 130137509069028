export default class home {

	constructor() {
		
		this.initializePage();
		this.mouseX = 0;
		this.mouseY = 0;
		this.initializeMousePosition();		

		if(document.body.classList.contains('template-home')) {
			this.initializeHome();
			
		}

		if(document.body.classList.contains('template-projects')) {
			this.initializeIndexEvents();
			this.initializeFilterEvents();
			this.initializeHeaderLogo();
		}

		if(document.body.classList.contains('template-archive')) {
			
			this.initializeArchiveEvents();
			this.initializeFilterEvents();
			this.initializeHeaderLogo();
			
		}

		if(document.body.classList.contains('template-project')) {
			this.initializeProject();
			this.initializeHeaderLogo();
		}

		if(document.body.classList.contains('template-page')) {
			this.initializeProject();
			this.initializeHeaderLogo();
		}

	}

	isMobile() {
		return document.body.clientWidth < 768;
	}

	initializeMousePosition() {
		document.addEventListener('mousemove', (event) => {
			this.mouseX = event.clientX;
			this.mouseY = event.clientY;
		});
	}

	initializePage() {

		document.body.classList.remove('out');

		window.addEventListener('pageshow', function(event) {
			document.body.classList.remove('out');
		});

		let lastScrollTop = 0;

		window.addEventListener('scroll', function() {
			let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
			if ((lastScrollTop > currentScroll && lastScrollTop - currentScroll > 100) || currentScroll <= 1) {
				document.body.classList.remove('hide-menu');
				lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
			} else if (currentScroll > lastScrollTop && currentScroll > 0) {
				lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
				document.body.classList.add('hide-menu');
			}
		}, false);
		
		
		document.querySelectorAll('.main-nav a').forEach(link => {
			link.addEventListener('click', (event) => {
				
				this.goToInternalLink(link.getAttribute('href'));
				event.preventDefault();
			});
		});

		if(this.isMobile()){
			document.querySelector('.menu-button').addEventListener('click', (event) => {
				document.body.classList.toggle('show-menu');
				event.preventDefault();
			});
		}
		
	}

	initializeHome() {

		setTimeout(() => {
			// setTimeout(() => {
				document.body.classList.add('hide-logo');
			// }, 100);

			document.body.classList.add('show-link');

			this.showNewStack(false);
				
		}, 3000);

		document.querySelectorAll('.shuffle-button').forEach(button => {

			button.addEventListener('click', (event) => {
				this.showNewStack(true);
				event.preventDefault();
			});
			
		});


		document.querySelectorAll('.home-project-images').forEach(container => {

			// 	//position images
			const items = container.querySelectorAll('li');
			const totalItems = items.length;
			// Define the center of the ellipse
			const centerX = 50; // 50% of the viewport width (50vw)
			const centerY = 50; // 50% of the viewport height (50vh)
			// Define the radii of the ellipse
			const radiusX = 20; // Adjust these to change the shape of your ellipse
			const radiusY = 25;
			items.forEach((item, index) => {
				// Calculate the angle for this item
				const angle = (index / totalItems) * 2 * Math.PI; // Evenly spaced angles
				// Convert polar coordinates (radius, angle) to Cartesian coordinates (x, y)
				const x = centerX + radiusX * Math.cos(angle); // Adjust for ellipse horizontal radius
				const y = centerY + radiusY * Math.sin(angle); // Adjust for ellipse vertical radius
				const rotate = angle * 0.75; // Random angle
				// Set the position of each item
				item.style.left = `${x}vw`;
				item.style.top = `${y}vh`;
				item.querySelector('a').style.transform = `rotate(${rotate - 1}deg)`; // Rotate the image
			});
    



			if(container.querySelectorAll('li').length === 1){
				container.parentElement.classList.add('single-project-images');
			}

			container.querySelectorAll('li img').forEach((card, index) => {
				var ranNumX = Math.ceil(Math.random() * 100) * (Math.round(Math.random()) ? 1 : -1);
				var ranNumY = Math.ceil(Math.random() * 100) * (Math.round(Math.random()) ? 1 : -1);
				var ranRotate = Math.ceil(Math.random() * 1) * (Math.round(Math.random()) ? 1 : -1);

			});


			let highestZIndex = 100;


			container.querySelectorAll('li').forEach((card, index) => {
				card.style.zIndex = highestZIndex; // Set initial z-index

				if (this.isMobile()) {

					let isDragging = false;
					let startX, startY;

					card.addEventListener('click', (event) => {
						if (event.target.classList.contains('card-title')) {
							card.click();
						}
					});

					card.addEventListener('touchstart', (e) => {
						isDragging = true;
						highestZIndex++; // Increment to ensure this is the highest
						card.style.zIndex = highestZIndex; // Assign the new highest z-index to the touched element
						container.querySelectorAll('li').forEach((card) => {
							card.classList.remove('at-front');
						});
						card.classList.add('at-front');

						// Get the initial touch coordinates
						const touch = e.touches[0];
						startX = touch.pageX - card.offsetLeft;
						startY = touch.pageY - card.offsetTop;

						// Prevent default touch behavior
						e.preventDefault();
					});

					card.addEventListener('touchmove', (e) => {
						if (!isDragging) return;

						const touch = e.touches[0];
						card.style.position = 'absolute';
						card.style.left = `${touch.pageX - startX}px`;
						card.style.top = `${touch.pageY - startY}px`;

						// Prevent default touch behavior
						e.preventDefault();
					});

					card.addEventListener('touchend', (event) => {
						if (isDragging && event.target.classList.contains('card-title')) {
							// Simulate a click event
							const clickEvent = new MouseEvent('click', {
								view: window,
								bubbles: true,
								cancelable: true
							});
							event.target.dispatchEvent(clickEvent);
						}
						isDragging = false;
					});


				} else {
					card.addEventListener('mouseenter', () => {
						highestZIndex++; // Increment to ensure this is the highest
						card.style.zIndex = highestZIndex; // Assign the new highest z-index to the hovered element
					});
				}
			});

			container.querySelectorAll('li a').forEach((card, index) => {
				
				const title = card.querySelector('.card-title');

				if(title && !this.isMobile()){

					card.addEventListener('mousemove', (e) => {
						card.classList.add('hovering');
						const x = e.clientX - card.getBoundingClientRect().left;
						const y = e.clientY - card.getBoundingClientRect().top;
						title.style.transform = `translate(${x}px, ${y}px)`;
					});

					card.addEventListener('mouseleave', () => {
						card.classList.remove('hovering');
					});

				}

				card.addEventListener('click', (event) => {
					if(!this.isMobile() || (this.isMobile() && card.parentElement.classList.contains('at-front') && event.target.classList.contains('card-title'))){

						if(card.classList.contains('collection-page-image-link')){
							const image = card.dataset.image;
							const galleryContainer = document.createElement('div');
							galleryContainer.classList.add('home-gallery-container');
							const galleryInner = document.createElement('div');
							galleryInner.classList.add('home-gallery-inner');
							const imageElement = document.createElement('img');
							imageElement.src = image;
							galleryInner.appendChild(imageElement);
							galleryContainer.appendChild(galleryInner);
							document.body.appendChild(galleryContainer);
							setTimeout(() => {
								document.body.classList.add('show-home-gallery');
							}, 10);

							galleryContainer.addEventListener('click', (event) => {
								document.body.classList.remove('show-home-gallery');
								setTimeout(() => {
									galleryContainer.remove();
								}, 600);
							});
						} else if(card.classList.contains('collection-page-video-link')){
							const video = card.dataset.video;
							const imgWidth = 1920;
							const imgHeight = 1080;
							const galleryContainer = document.createElement('div');
							galleryContainer.classList.add('home-gallery-container');

							const galleryInner = document.createElement('div');
							galleryInner.classList.add('home-gallery-inner');

							const videoElement = document.createElement('video');
							videoElement.src = video;
							videoElement.autoplay = true;
							videoElement.muted = false;
							videoElement.controls = false;
							videoElement.playsInline = true;
							videoElement.loop = true;
							// videoElement.width = imgWidth;
							// videoElement.height = imgHeight;

							galleryInner.appendChild(videoElement);
							galleryContainer.appendChild(galleryInner);
							document.body.appendChild(galleryContainer);
							setTimeout(() => {
								document.body.classList.add('show-home-gallery');
							}, 10);

							galleryContainer.addEventListener('click', (event) => {
								document.body.classList.remove('show-home-gallery');
								setTimeout(() => {
									galleryContainer.remove();
								}, 600);
							});
						} else {
							const href = card.getAttribute('href');
							this.goToInternalLink(href);
						}
					}
					
					event.preventDefault();
				});
			});
			
		});

	}

	showNewStack(hideOldStack) {

		const tickerDelay = 160;

		document.body.classList.remove('allow-card-interaction');

		const currentCardsContainer = document.querySelector('.current-home-cards');
		
		const homeCards = document.querySelectorAll('.home-cards');
		const currentIndex = Array.from(homeCards).indexOf(currentCardsContainer);
		const nextIndex = currentIndex === homeCards.length - 1 ? 0 : currentIndex + 1;
		const nextCardsContainer = homeCards[nextIndex];

		let tagPause;

		// preload next images
		nextCardsContainer.querySelectorAll('img, video').forEach(item => {
			const src = item.getAttribute('data-src');
			if (src && item.src !== src) {
				item.src = src;
			}
		});

		if(hideOldStack) {
			

			tagPause = currentCardsContainer.querySelectorAll('.home-project-tags li').length * tickerDelay;

			let tagDelay = 0;
			currentCardsContainer.querySelectorAll('.home-project-tags li').forEach((li, index) => {
				setTimeout(() => {
					li.classList.add('hide-tag');
				}, tagDelay += tickerDelay);
			});

			const oldCards = currentCardsContainer.querySelectorAll('.home-project-images li');
			
			let cardDelay = 0;
			
			Array.from(oldCards).reverse().forEach((card, index) => {
				setTimeout(() => {
					const inScale = parseFloat(card.dataset.inscale);
					const newScale = parseFloat(card.dataset.scale);
					const outScale = parseFloat(card.dataset.outscale);

					card.style.transform = `scale(${newScale - 0.05})`;
					setTimeout(() => {
						card.style.opacity = 0;
					}, 25);
					
					if(index === oldCards.length - 1) {
						setTimeout(() => {
							currentCardsContainer.classList.remove('current-home-cards');
							nextCardsContainer.classList.add('current-home-cards');
							this.showNewStack(false);
						}, tickerDelay);
					}
				}, cardDelay += tickerDelay);
			});

		} else {

			const newCards = currentCardsContainer.querySelectorAll('.home-project-images li');
			const totalCards = currentCardsContainer.querySelectorAll('.home-project-images li').length;
			const cardsPause = (newCards.length) * tickerDelay;
			let tagDelay = 0;
			newCards.forEach((card, index) => {
				setTimeout(() => {
					const inScale = parseFloat(card.dataset.inscale);
					const newScale = parseFloat(card.dataset.scale);
					
					card.style.transform = `scale(${newScale - 0.15})`;

					setTimeout(() => {
						card.style.opacity = 1;
						card.style.transform = `scale(${newScale})`;
					}, 10);
					// this.orderCards();
				}, tagDelay += tickerDelay);
			});

			const lastCard = newCards[totalCards - 1];
			const url = lastCard.dataset.url;
			const title = lastCard.dataset.title;

			setTimeout(() => {
				document.body.classList.add('allow-card-interaction');

				let tagDelay = 0;
				document.querySelectorAll('.current-home-cards .home-project-tags li').forEach(li => {
					setTimeout(() => {
						li.classList.remove('hide-tag');
					}, tagDelay += tickerDelay);
				});
			}, cardsPause);

		}

	}

	// orderCards() {
	// 	const currentCardsContainer = document.querySelector('.current-home-cards');
	// 	const currentCards = Array.from(currentCardsContainer.querySelectorAll('.home-project-images li:not(.hide-image)'));
	// 	const totalCards = currentCardsContainer.querySelectorAll('.home-project-images li:not(.hide-image)').length;

	// 	let scale = 1 - ((totalCards - 1) * 0.1);
	
	// 	currentCards.forEach((card, index) => {
	// 		// card.style.zIndex = totalCards - index; // Set higher zIndex for earlier cards
	// 		// card.style.transform = `scale(${scale + 0.1})`;
	// 		card.style.opacity = 1;
	// 		card.style.transform = `scale(${scale})`;
	// 		scale = scale + 0.1 > 1 ? 1 : scale + 0.1; // Ensure the scale doesn't go below 0
	// 	});
	// }
	


	initializeProject() {

		document.querySelectorAll('.project-blocks-container figure').forEach((figure, index) => {
			if(figure.querySelectorAll('li').length === 1){
				figure.classList.add('gallery-single-image');
			}
			if(figure.querySelectorAll('li').length === 2){
				figure.classList.add('gallery-two-images');
			}
			if(figure.querySelectorAll('li').length > 2){
				figure.classList.add('gallery-multiple-images');
				const ul = figure.querySelector('ul');
				ul.classList.add('gallery-container');

					var flkty = new Flickity(ul, {
						cellSelector: 'li',
						contain: true,
						// autoPlay: 5000,
						imagesLoaded: true,
						fullscreen: false,
						adaptiveHeight: true,
						pageDots: false,
						wrapAround: true,
					});
			}
		});

		window.addEventListener('scroll', function() {
			const distanceFromBottom = document.documentElement.scrollHeight - window.innerHeight - window.scrollY;

		
			if (distanceFromBottom < 120) {
				document.body.classList.add('at-bottom');
			} else {
				document.body.classList.remove('at-bottom');
			}
		});

		if(document.querySelector('main nav a')){
			document.querySelector('main nav a').addEventListener('click', (event) => {
				const href = document.querySelector('main nav a').getAttribute('href');
				this.goToInternalLink(href);
	
				event.preventDefault();
			});
	
		}

		if(document.querySelector('.play-soundtrack')){
			let audio;
        	let isAudioLoaded = false;

			var button = document.querySelector('.play-soundtrack-button');
				button.addEventListener('click', function() {
					const soundtrackUrl = this.dataset.soundtrack;

					if (!audio) {
						audio = new Audio(soundtrackUrl);
						audio.addEventListener('ended', function() {
							document.body.classList.remove('playing-audio');
							document.body.classList.add('audio-ended');
						});
					}

					if (audio.paused) {
						audio.play();
						document.body.classList.add('playing-audio', 'audio-loaded');
						document.body.classList.remove('paused-audio', 'audio-ended');
					} else {
						audio.pause();
						document.body.classList.add('paused-audio');
						document.body.classList.remove('playing-audio');
					}

					isAudioLoaded = true;
				});
			}


			if(document.querySelector('.soundtrack-follower')){

				document.querySelector('.featured-image-container').addEventListener('click', (event) =>{
					const audio = document.querySelector('.soundtrack-follower audio');

					audio.addEventListener('ended', function() {
						document.body.classList.remove('playing-audio');
						document.body.classList.add('audio-ended');
					});

					if (audio.paused) {
						audio.play();
						document.body.classList.add('playing-audio');
						document.body.classList.remove('paused-audio', 'audio-ended');
					} else {
						audio.pause();
						document.body.classList.add('paused-audio');
						document.body.classList.remove('playing-audio');
					}
				});

				document.addEventListener('mousemove', (event) =>{
					document.querySelector('.soundtrack-follower').style.transform = `translate(${this.mouseX}px, ${this.mouseY}px)`;
				});

				}
		
	}

	goToInternalLink(href) {
		if(!this.isMobile()){
			document.body.classList.add('out');

			var preloadLink = document.createElement("link");
			preloadLink.rel = "prefetch";
			preloadLink.href = href;
			document.head.appendChild(preloadLink);
			
			setTimeout(() => {
				window.location.href = href;
			}, 400);
		} else {
			window.location.href = href;
		}
	}

	initializeFilterEvents() {
		

		document.querySelector('.filter-button').addEventListener('click', (event) => {
			if(document.body.classList.contains('filters-open')) {
				document.body.classList.remove('filters-open');

				const toggleElements = document.querySelectorAll('.projects-filter-list');
				this.toggleShow(toggleElements, false);

			} else {
				document.body.classList.add('filters-open');
				const toggleElements = document.querySelectorAll('.projects-filter-list');
				this.toggleShow(toggleElements, true);
			}
			
		});

		const timeoutsMap = new Map();
		const isArchive = document.body.classList.contains('template-archive');

		document.querySelectorAll('.projects-filter-list li button').forEach(button => {
			button.addEventListener('click', (event) => {
				
				const filter = button.getAttribute('data-filter');
				let time = 80;

				if (button.classList.contains('active-filter')) {
					button.classList.remove('active-filter');
				} else {
					button.classList.add('active-filter');
				}

				const activeFilters = Array.from(document.querySelectorAll('.projects-filter-list button.active-filter'))
										.map(activeButton => activeButton.getAttribute('data-filter'));

				document.querySelectorAll('.projects > li').forEach(li => {
					const filtersAttr = li.getAttribute('data-filters');
					const filters = filtersAttr ? filtersAttr.split(',') : [];
					const isMatch = activeFilters.length === 0 || activeFilters.every(activeFilter => filters.includes(activeFilter));

					// Clear existing timeout for this list item
					if (timeoutsMap.has(li)) {
						clearTimeout(timeoutsMap.get(li));
						timeoutsMap.delete(li);
					}

					if (activeFilters.length === 0 || isMatch) {
						if (li.classList.contains('hide')) {
							const timeout = isArchive ? 0 : time += 80;
							const timeoutId = setTimeout(() => {
								li.classList.remove('hide');
							}, timeout);
							timeoutsMap.set(li, timeoutId);
						}
					} else {
						if (!li.classList.contains('hide')) {
							const timeout = isArchive ? 0 : time += 80;
							const timeoutId = setTimeout(() => {
								li.classList.add('hide');
							}, timeout);
							timeoutsMap.set(li, timeoutId);
						}
					}
				});
			});
		});


		document.querySelector('.clear-button').addEventListener('click', (event) => {
			let time = 80;
			document.querySelectorAll('.projects-filter-list button').forEach(button => {
				button.classList.remove('active-filter');
			});
		
			document.querySelectorAll('.projects > li').forEach(li => {
				// Clear existing timeout for this list item
				if (timeoutsMap.has(li)) {
					clearTimeout(timeoutsMap.get(li));
					timeoutsMap.delete(li);
				}
				
				const timeout = isArchive ? 0 : time += 80;
				const timeoutId = setTimeout(() => {
					li.classList.remove('hide');
				}, timeout);
				timeoutsMap.set(li, timeoutId);
			});
		});
		
		// Event listener for '.shuffle-projects-button'
		document.querySelector('.shuffle-projects-button').addEventListener('click', (event) => {
			let time = 80;
			document.querySelectorAll('.projects-filter-list button').forEach(button => {
				button.classList.remove('active-filter');
			});
		
			document.querySelectorAll('.projects > li').forEach(li => {
				// Clear existing timeout for this list item
				if (timeoutsMap.has(li)) {
					clearTimeout(timeoutsMap.get(li));
					timeoutsMap.delete(li);
				}
				
				const timeout = isArchive ? 0 : time += 80;
				const timeoutId = setTimeout(() => {
					li.classList.remove('hide');
				}, timeout);
				timeoutsMap.set(li, timeoutId);
			});
		
			// Shuffle logic
			// Convert NodeList to array
			let lists = Array.from(document.querySelectorAll('.projects-filter-list:not(.projects-filter-list-interface):not(.projects-filter-list:first-child)'));

			// Shuffle the array
			lists.sort(() => Math.random() - 0.5);

			// Select only the first two lists from the shuffled array
			lists.slice(0, 2).forEach(list => {
				let totalItems = list.querySelectorAll('li').length;
				let randomItem = Math.floor(Math.random() * totalItems);
				list.querySelectorAll('li')[randomItem].querySelector('button').click();
			});
		});
	}

	initializeArchiveEvents() {

		// setTimeout(() => {
		// 	this.toggleShow(document.querySelectorAll('.projects > li'), true);
		// }, 600);

		document.querySelectorAll('.projects > li').forEach(li => {
			li.classList.remove('hide');
		});

		document.querySelectorAll('.archive-list > li a').forEach(link => {
			const title = link.querySelector('.project-grid-item-title');

			link.addEventListener('mousemove', (e) => {
				link.classList.add('hovering');
				title.style.transform = `translate(${this.mouseX}px, ${this.mouseY}px)`;
			});

			link.addEventListener('mouseleave', () => {
				link.classList.remove('hovering');
			});

			link.addEventListener('click', (event) => {
				const href = link.getAttribute('href');
				this.goToInternalLink(href);  
				event.preventDefault();
			});


		});
		
	}

	initializeIndexEvents() {

		setTimeout(() => {
			this.toggleShow(document.querySelectorAll('.projects > li'), true);
		}, 600);

		
		document.querySelectorAll('.project-list-item a').forEach(link => {

			if(!this.isMobile()){

				let animationFrame;
				let isResetting = false; // Flag to track if it's currently resetting
				const projectOuter = link.querySelector('.project-list-item-outer');
				let projectInner = link.querySelector('.project-list-item-inner');
				const totalInners = 3; // Number of times to duplicate the inner content
				let innerWidth;
				let totalWidth;
				let currentX = 0;

				let frameTimes = [];
				let lastFrameTime = 0;
				let speed = 0.8; // Adjust speed as needed


				

				// Function to update dimensions
				function updateDimensions() {
					innerWidth = projectInner.offsetWidth;
					totalWidth = innerWidth * totalInners;
				}

				// Initialize dimensions
				updateDimensions();

				// Duplicate the inner content
				for (let i = 0; i < totalInners - 1; i++) {
					const clone = projectInner.cloneNode(true);
					projectOuter.appendChild(clone);
				}

				let animationInterval;
				const movementPerInterval = 1; // 1px per 10ms
				const intervalDuration = 10; // 10ms

				const animate = () => {
					animationInterval = setInterval(() => {
						currentX -= movementPerInterval;
						if (Math.abs(currentX) >= totalWidth / totalInners) {
							currentX += totalWidth / totalInners;
						}
						projectOuter.style.transform = `translateX(${currentX}px)`;
					}, intervalDuration);
				};

				const resetAnimation = () => {
					clearInterval(animationInterval);
					isResetting = true;

					const resetSpeed = Math.abs(currentX % innerWidth) / 400; // 200px per second, adjusted for 10ms interval
					const easingDistance = 0;

					const reset = () => {
						if (isResetting) {
							let distanceToStart = Math.abs(currentX % innerWidth);
							let speed = resetSpeed * intervalDuration;

							if (distanceToStart < easingDistance) {
								speed *= distanceToStart / easingDistance;
							}

							if (distanceToStart > 1) {
								currentX += (currentX > 0) ? -speed : speed;
								projectOuter.style.transform = `translateX(${currentX}px)`;
								setTimeout(reset, intervalDuration);
							} else {
								isResetting = false;
								currentX = 0; // Reset to starting position
								projectOuter.style.transform = `translateX(0px)`;
							}
						}
					};

					reset();
				};

				link.addEventListener('mouseenter', () => {
					if (isResetting) {
						isResetting = false;
						clearInterval(animationInterval);
					}
					updateDimensions();
					projectInner = link.querySelector('.project-list-item-inner');
					animate();
				});

				link.addEventListener('mouseleave', () => {
					updateDimensions();
					resetAnimation();
				});

			} else {
				const figure = link.nextElementSibling; // Assuming the figure is the next sibling element
				if (figure && figure.tagName.toLowerCase() === 'figure') {
					link.appendChild(figure);
				}
			}

			link.addEventListener('click', (event) => {
				const href = link.getAttribute('href');
				// Assuming `this` refers to the appropriate context, or adjust accordingly
				this.goToInternalLink(href);  
				event.preventDefault();
			});

			
		});
		

	}

	initializeHeaderLogo() {
		
		let moved = false;
		let targetWeights = [];
		let currentWeights = [];
		let startTimes = [];

		const minWeight = 0;
		const maxWeight = 70;

		const h1Element = document.querySelector('.main-logo');
    	const characters = h1Element.textContent.split('');
    	h1Element.innerHTML = characters.map(char => `<span>${char}</span>`).join('');
		
		window.onload = function(){
			const spans = document.querySelectorAll('.main-logo span');
			spans.forEach((span, index) => {
			  startTimes[index] = 0;
			  currentWeights[index] = 0;
			  targetWeights[index] = maxWeight;
			});
			if (!animationRunning) {
			  requestAnimationFrame(animateWeights);
			}
		};
		
		document.querySelector('.main-logo').addEventListener('mousemove', function(e) {
		  const container = this.getBoundingClientRect();
		  const x = e.clientX - container.left;
		  const spans = this.querySelectorAll('span');
		  
		  spans.forEach((span, index) => {
			const charBox = span.getBoundingClientRect();
			const distance = Math.abs(x - (charBox.left + charBox.width / 2 - container.left));
			const maxDistance = container.width / 2;
			const weight = map(distance, 0, maxDistance, minWeight, maxWeight); // Inverted weights
			targetWeights[index] = weight;
		  });
		  
		  if (!animationRunning) {
			requestAnimationFrame(animateWeights);
		  }
		});
		
		document.querySelector('.main-logo').addEventListener('mouseleave', function(e) {
		  const spans = this.querySelectorAll('span');
		  spans.forEach((span, index) => {
			targetWeights[index] = maxWeight; // Default weight is now 220
		  });
		  
		  if (!animationRunning) {
			requestAnimationFrame(animateWeights);
		  }
		});
		
		function map(value, start1, stop1, start2, stop2) {
			// First, map the value from one range to another
			let mappedValue = start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
		
			// Then, clamp the result to be within the start2 to stop2 range
			return Math.min(Math.max(mappedValue, start2), stop2);
		}
		
		let animationRunning = false;
		
		function animateWeights() {
		  animationRunning = true;
		  const spans = document.querySelectorAll('.main-logo span');
		  let stillAnimating = false;
		  
		  spans.forEach((span, index) => {
			if (currentWeights[index] === undefined) {
			  currentWeights[index] = maxWeight; // Default weight is now 220
			}
			
			if (Date.now() >= startTimes[index] && Math.abs(currentWeights[index] - targetWeights[index]) > 1) {
			  stillAnimating = true;
			  // If target weight is less than current weight, change rate is 0.06 (2 times quicker)
			  // If target weight is greater than current weight, change rate is 0.03
			  const changeRate = targetWeights[index] < currentWeights[index] ? 0.1 : 0.015;
			  currentWeights[index] += (targetWeights[index] - currentWeights[index]) * changeRate;
			  span.style.fontVariationSettings = `'wght' ${Math.round(currentWeights[index])}`;
			}
		  });
		  
		  if (stillAnimating) {
			requestAnimationFrame(animateWeights);
		  } else {
			animationRunning = false;
		  }
		}

	}


	getActualHeight(element) {
		const originalOverflow = element.style.overflow;
		const originalHeight = element.style.height;
		element.style.overflow = 'visible';
		element.style.height = 'auto';
		const scrollHeight = element.scrollHeight;
		element.style.overflow = originalOverflow;
		element.style.height = originalHeight;
		return scrollHeight;
	}


	toggleShow(elements, show) {
		// Ensure elements is an array to handle NodeList and other iterable collections uniformly
		elements = Array.from(elements);
	
		// Reverse the order for hiding
		if (!show) {
			elements.reverse();
		}
		
		// Set a base delay time
		const baseDelay = 80;
	
		// Apply the toggle class change with the delay
		elements.forEach((element, index) => {
			setTimeout(() => {
				if (show) {
					element.classList.remove('hide');
				} else {
					element.classList.add('hide');
				}
			}, baseDelay * index);
		});
	}
	

}